import { auth } from '../../../firebase';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  getRolePhaseCodes,
  getRolesInfo,
  postRoleArgs,
} from '../../services/RolesService';
import { useRolesAccessContext } from '../../context/RolesAccessContext';
import { removeDuplicates } from '../../../../src/utils/roleUtils';
import { Access } from '../../Interfaces/Role.interfaces';
import { getUserAccess } from '../../services/UsersService';
import { ProgressSpinner } from 'primereact/progressspinner';

type GetRolesProps = {
  children?: React.ReactNode;
};

const GetRoles = ({ children }: GetRolesProps) => {
  const { setRolesAccess, setIsAdmin, setUserType, setRolePhases } =
    useRolesAccessContext();
  const [enabled, setEnabled] = useState<boolean>(false);
  const [roles, setRoles] = useState<string[]>();
  const [access, setAccess] = useState<Access[]>([]);
  const [accessLoading, setAccessLoading] = useState<boolean>(true);
  const [roleLoading, setRoleLoading] = useState<boolean>(true);

  const rolePhases = useQuery({
    queryKey: ['getRolePhases'],
    queryFn: ({ signal }) => {
      return getRolePhaseCodes(signal);
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setRolePhases(data);
      setRoleLoading(false);
    },
    enabled: false,
  });

  useQuery({
    queryKey: ['getUserAccess'],
    queryFn: ({ signal }) => {
      return getUserAccess(signal);
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setAccess((access) => {
        const newAccess = data || [];
        if (access.length > 0) {
          return removeDuplicates([...access, ...newAccess]).filter(
            (acc) => !acc.blocked
          );
        } else {
          return newAccess;
        }
      });

      setAccessLoading(false);
    },
    enabled,
  });

  useQuery({
    queryKey: ['getUserRolesInfo', roles],
    queryFn: ({ signal }) => {
      return getRolesInfo(roles ?? [], signal);
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let list: Access[] = [];
      let shouldHidePhases = false;
      data.forEach((role: postRoleArgs) => {
        list = [...list, ...role.access];
        shouldHidePhases = shouldHidePhases || !!role.hidePhaseCodes;
      });

      setAccess((access) => {
        if (access.length > 0) {
          return removeDuplicates([...access, ...list]).filter(
            (acc) => !acc.blocked
          );
        } else {
          return removeDuplicates(list).filter((acc) => !acc.blocked);
        }
      });

      if (shouldHidePhases) {
        rolePhases.refetch();
      } else {
        setRoleLoading(false);
      }
    },
    enabled: !!roles,
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        auth.currentUser
          ?.getIdTokenResult(true)
          .then((tokenData) => {
            const roles = tokenData?.claims['roles'];
            const isAdmin = tokenData?.claims['isAdmin'];
            const userType = tokenData?.claims['userType'];

            setIsAdmin(isAdmin);
            setUserType(userType);

            if (roles?.length > 0) {
              setRoles(roles);
            }
            setEnabled(true);
          })
          .catch((error) => {
            setEnabled(true);
            console.log(`User token error: ${error}`);
          });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setRolesAccess(access);
  }, [access]);

  if (accessLoading || (roles && roleLoading)) {
    return (
      <div className="h-screen flex align-items-center">
        <ProgressSpinner />
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default GetRoles;
