import axios from 'axios';
import { auth } from '../../firebase';
import { cleanJsonEmptyValues } from '../../utils/formatUtils';

const apiUrl = process.env['NX_API_URL'];

export const fecthCBContractList = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/contractBillings/contractList`,
    {
      signal,
      params: { companyID },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fecthCBResume = async (
  companyID: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/contractBillings`, {
    signal,
    params: { companyID },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const result = response.data;
  return result;
};

type fecthCBProgressWorksheetArgs = {
  companyID: string;
  contract: string;
  edit?: boolean;
  draftID?: string;
  hideBilled?: boolean;
  info?: string;
};

export const fecthCBProgressWorksheet = async (
  params: fecthCBProgressWorksheetArgs,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/contractBillings/contractProgress`,
    {
      signal,
      params: cleanJsonEmptyValues(params),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

type fecthCBContractsArgs = {
  companyID: string;
  status?: number;
  department?: number;
};

export const fecthCBContracts = async (
  params: fecthCBContractsArgs,
  signal?: AbortSignal
) => {
  const response = await axios.get(`${apiUrl}/api/contractBillings/contracts`, {
    signal,
    params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  const result = response.data;
  return result;
};

type fecthCBActiveContractsInfoProps = {
  companyID: string;
  state?: string;
  period?: string;
};

export const fecthCBActiveContractsInfo = async (
  params: fecthCBActiveContractsInfoProps,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/contractBillings/activeContractsInfo`,
    {
      signal,
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fetchLatestContracts = async (
  companyID: string,
  limit: number,
  department?: number,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/contractBillings/latestContracts`,
    {
      signal,
      params: { companyID, limit, department },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const postProgressBillingDraft = async (
  companyID: string,
  contract: string,
  amounts: Record<number, number>,
  releasedRetainage?: number
) => {
  const response = await axios.post(
    `${apiUrl}/api/contractBillings/progressBilling`,
    {
      companyID,
      contract,
      amounts: Object.keys(amounts).length > 0 ? amounts : undefined,
      releasedRetainage,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

export const putProgressBillingDraft = async (
  draftID: string,
  fromApproval: boolean,
  amounts?: Record<number, number>,
  retainage?: number
) => {
  const response = await axios.put(
    `${apiUrl}/api/contractBillings/progressBilling/${draftID}`,
    {
      amounts: Object.keys(amounts || {}).length > 0 ? amounts : undefined,
      releasedRetainage: retainage ?? undefined,
      fromApproval,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

export const putProgressBillingSubmitDraft = async (draftID: string) => {
  const response = await axios.put(
    `${apiUrl}/api/contractBillings/progressBilling/submit/${draftID}`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

export const putProgressBillingApproveDraft = async (
  draftID: string,
  companyID: string
) => {
  const response = await axios.put(
    `${apiUrl}/api/contractBillings/progressBilling/approve/${draftID}`,
    { companyID },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

export const putProgressBillingRejectDraft = async (
  dratID: string,
  reason: string
) => {
  const response = await axios.put(
    `${apiUrl}/api/contractBillings/progressBilling/reject/${dratID}`,
    { reason },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};

export const getSubmitedProgressBilling = async (
  companyID: string,
  contract: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/contractBillings/progressBilling/submit`,
    {
      signal,
      params: { companyID, contract },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

export const getLastApprovedProgressBilling = async (
  companyID: string,
  contract: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/contractBillings/progressBilling/approve/latest`,
    {
      signal,
      params: { companyID, contract },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  const result = response.data;
  return result;
};

type ContractItemsFilters = {
  companyID: string;
  contract?: string;
  info?: string;
};

export const fecthContractItems = async (
  params: ContractItemsFilters,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/contractBillings/contractItems`,
    {
      signal,
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fecthContractCustomer = async (
  companyID: string,
  contract: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/contractBillings/contractCustomer`,
    {
      signal,
      params: { companyID, contract },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );
  const result = response.data;
  return result;
};

export const fecthCBMonthlyBilling = async (
  companyID: string,
  contracts?: string[],
  year?: number,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${apiUrl}/api/contractBillings/monthlyBilling`,
    {
      signal,
      params: { companyID, contracts, year },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  return response.data;
};
