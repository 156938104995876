import { Accordion, AccordionTab } from 'primereact/accordion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import ModuleAccordionHeader from './ModuleAccordionHeader';
import ReportOption from './ReportOption';
import {
  Access,
  AccessForm,
  EditValues,
  SystemAccess,
} from '../../Interfaces/Role.interfaces';
import { RegisterFormFields } from './RegisterForm';

type ModulesAccordionProps = {
  sections: Record<string, SystemAccess[]>;
  defaultAccess?: Access[];
  defaultSet?: () => void;
};

const ModulesAccordion = ({
  sections,
  defaultAccess,
  defaultSet,
}: ModulesAccordionProps) => {
  const [accordionTabs, setAccordionTabs] = useState<JSX.Element[]>([]);
  const [activeTab, setActiveTab] = useState<number[]>([]);
  const [checkedModules, setCheckedModules] = useState<Record<string, boolean>>(
    {}
  );
  const { setValue } = useFormContext<RegisterFormFields>();
  const { fields, append } = useFieldArray({
    name: 'access',
  });
  const fieldsInitialized = useRef(fields?.length > 0);
  const currentDefaults = useRef<Access[]>([]);
  const results = useWatch({ name: 'access' });

  const formatEditionValues = (
    edition?: (boolean | 'approval' | 'no-approval')[]
  ) => {
    return edition?.map((option) => {
      let label = `${option}`;
      let value: EditValues = option;

      switch (label) {
        case 'false':
          label = 'View Only';
          value = 'view-only';
          break;
        case 'true':
          label = 'Standard';
          break;
        case 'approval':
          label = 'With Approval';
          break;
        case 'no-approval':
          label = 'Without Approval';
          break;
        default:
          break;
      }

      return { label, value };
    });
  };

  const updateCheckedModules = (module: string, checked: boolean) => {
    setCheckedModules((value) => {
      const tempChecked = { ...value };
      tempChecked[module] = checked;
      return tempChecked;
    });
  };

  const accordionTab = (
    module: string,
    startIndex: number,
    children: JSX.Element[]
  ) => {
    return (
      <AccordionTab
        key={module}
        header={
          <ModuleAccordionHeader
            module={module}
            checkedModules={checkedModules}
            updateModule={updateCheckedModules}
            startIndex={startIndex}
            count={children.length}
          />
        }
      >
        {children}
      </AccordionTab>
    );
  };

  const accordionTabCB = useCallback(accordionTab, [checkedModules]);

  const buildAccordeon = (fields: AccessForm[]) => {
    const result: JSX.Element[] = [];
    let optionsList: JSX.Element[] = [];
    let currentModule = fields[0]?.module;

    fields.forEach((item, index) => {
      if (item.module !== currentModule && optionsList.length) {
        result.push(
          accordionTabCB(currentModule, index - optionsList.length, optionsList)
        );
        optionsList = [];
      }
      currentModule = item.module;

      const option = (
        <ReportOption key={item.report} item={item} index={index} />
      );
      optionsList.push(option);
    });

    if (optionsList.length) {
      result.push(
        accordionTabCB(
          currentModule,
          fields.length - optionsList.length,
          optionsList
        )
      );
    }

    return result;
  };

  const buildAccordeonCB = useCallback(buildAccordeon, [accordionTabCB]);

  useEffect(() => {
    if (!fieldsInitialized.current) {
      const reports: AccessForm[] = [];

      Object.keys(sections).forEach((key) => {
        const reportList = sections[key];

        reportList.forEach((option) => {
          const { value, edition, notification, approval } = option;

          const fields = {
            report: value,
            label: value.replace(/_/g, ' '),
            editOption: formatEditionValues(edition),
            notification,
            module: key,
            approvalOption:
              typeof approval === 'number'
                ? [...Array(approval + 1).keys()].map((i) => i + 1)
                : approval,
          };
          reports.push(fields);
        });
      });

      append(reports);
      fieldsInitialized.current = true;
    }
  }, [append]);

  useEffect(() => {
    if (fields.length !== 0) {
      const list = buildAccordeonCB(fields as unknown as AccessForm[]);
      setAccordionTabs(list);
    }
  }, [fields, buildAccordeonCB]);

  useEffect(() => {
    if (
      results?.length &&
      defaultAccess &&
      defaultAccess !== currentDefaults.current
    ) {
      setActiveTab([]);

      const pevDefaults = currentDefaults.current;

      results.forEach((access: AccessForm, index: number) => {
        const { editable, shouldNotify, shouldApprove } = access;
        const newAccess = { ...access };

        const pevAccess = pevDefaults?.find(
          (pevAccess) => pevAccess.report === access.report
        );

        if (pevAccess) {
          const prevEditable =
            !pevAccess.editable && pevAccess.editable != null
              ? `view-only`
              : pevAccess.editable;

          const sameApproval = Array.isArray(access.approvalOption)
            ? shouldApprove == pevAccess.approvalOrder
            : shouldApprove == pevAccess.shouldApprove;

          if (
            editable == prevEditable &&
            shouldNotify == pevAccess?.shouldNotify &&
            sameApproval
          ) {
            delete newAccess.selected;
            delete newAccess.editable;
            delete newAccess.shouldNotify;
            delete newAccess.shouldApprove;
          }
        }

        const defAccess = defaultAccess.find(
          (defAccess) => defAccess.report === access.report
        );

        if (defAccess) {
          newAccess.selected = true;

          if (
            newAccess.shouldNotify == null &&
            defAccess.shouldNotify != null
          ) {
            newAccess.shouldNotify = defAccess.shouldNotify;
          }

          if (newAccess.editable == null && defAccess.editable != null) {
            newAccess.editable = !defAccess.editable
              ? `view-only`
              : defAccess.editable;
          }

          if (
            newAccess.shouldApprove == null &&
            defAccess.shouldApprove != null
          ) {
            if (Array.isArray(access.approvalOption)) {
              newAccess.shouldApprove = defAccess.approvalOrder;
            } else {
              newAccess.shouldApprove = defAccess.shouldApprove;
            }
          }
        }

        setValue(`access.${index}`, newAccess);
      });

      if (defaultSet) {
        defaultSet();
      }
      currentDefaults.current = defaultAccess;
    }
  }, [results, defaultAccess]);

  useEffect(() => {
    let currentModule = results ? results[0]?.module : null;
    let counter = 0;
    let checkedCounter = 0;
    results?.forEach((access: AccessForm) => {
      if (access.module === currentModule) {
        counter += 1;
        checkedCounter += access.selected ? 1 : 0;
      } else {
        updateCheckedModules(
          currentModule,
          counter === checkedCounter && counter !== 0
        );

        currentModule = access.module;
        counter = 1;
        checkedCounter = access.selected ? 1 : 0;
      }
    });

    if (counter !== 0) {
      updateCheckedModules(
        currentModule,
        counter === checkedCounter && counter !== 0
      );
    }
  }, [results]);

  return (
    <Accordion
      className="w-full"
      multiple
      activeIndex={activeTab}
      onTabChange={(e) => {
        const element = e.originalEvent.target as HTMLElement;

        if (!element.className.includes('p-checkbox')) {
          setActiveTab(e.index as unknown as number[]);
        }
      }}
    >
      {accordionTabs}
    </Accordion>
  );
};

export default ModulesAccordion;
