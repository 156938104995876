import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useFiltersCache from '../../hooks/useFiltersCache';
import { CBFilterFields } from '../../Interfaces/Accounting/ContractBillings.interface';
import { fetchContractBillingFilters } from './CBFilters';
import CryptoJS from 'crypto-js';

const params: { label: CBFilterFields; isEncrypted?: boolean }[] = [
  { label: 'reportType' },
  { label: 'contract', isEncrypted: true },
  { label: 'submittedID' },
];

const formatFilters = (
  filters: fetchContractBillingFilters,
  filtersToIgnore?: CBFilterFields[]
) => {
  const newYear = filters.year ? filters.year.toString() : null;
  const formatttedFilters = { ...filters, year: newYear };

  if (filtersToIgnore) {
    filtersToIgnore.forEach((filter) => delete formatttedFilters[filter]);
  }

  return formatttedFilters;
};

const CBParams = () => {
  const [cookies] = useCookies(['filters']);
  const [searchParams, setSearchParams] = useSearchParams();
  const { setValue } = useFormContext<fetchContractBillingFilters>();
  const filters = useWatch<fetchContractBillingFilters>();
  useFiltersCache({
    tab: 'CB',
    filters: formatFilters(filters, ['lastApprovedID']),
  });
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    const secretKey = process.env['NX_CRYPTO_PUBLIC_KEY'] ?? '';
    let hasParam = false;
    params.forEach((param) => {
      let value = searchParams.get(param.label);
      if (value) {
        if (param.isEncrypted) {
          const bytes = CryptoJS.AES.decrypt(value, secretKey);
          value = bytes.toString(CryptoJS.enc.Utf8);
        }

        setValue(param.label, value);
        hasParam = true;
      }
    });

    if (hasParam) {
      setSearchParams();
      setRendered(true);
    } else if (!rendered && cookies.filters?.tab === 'CB') {
      setRendered(true);
      const cookieFilters = cookies.filters?.filters;

      for (const i in cookieFilters) {
        const filterValue =
          i === 'year' ? new Date(cookieFilters[i]) : cookieFilters[i];
        setValue(i as CBFilterFields, filterValue);
      }
    }
  }, [setValue, rendered, setRendered, cookies.filters]);

  return <span />;
};

export default CBParams;
