import { useQuery } from '@tanstack/react-query';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import WrapperButton from '../../../components/buttons/WrapperButton';
import ConfirmationDialog from '../../../components/messages/ConfirmationDialog';
import ErrorToast, { showToast } from '../../../components/messages/ErrorAlert';
import { useCompanyContext } from '../../../context/CompanyContext';
import { ApiError } from '../../../Interfaces/Generic.interfaces';
import { getLastApprovedProgressBilling } from '../../../services/ContractBillingsService';
import { fetchContractBillingFilters } from '../CBFilters';

const ProgressBillingLastApproved = () => {
  const { selectedCompany } = useCompanyContext();
  const { setValue } = useFormContext<fetchContractBillingFilters>();
  const contract = useWatch<fetchContractBillingFilters>({
    name: 'contract',
  }) as string;
  const toast = useRef<Toast>(null);

  const { refetch, isFetching } = useQuery({
    queryKey: ['getLastApprovedBilling', selectedCompany, contract],
    queryFn: ({ signal }) => {
      return getLastApprovedProgressBilling(
        selectedCompany!.id,
        contract,
        signal
      );
    },
    onSuccess: (data) => {
      setValue('lastApprovedID', data.id);
    },
    onError: (error: ApiError) => {
      showToast(
        'error',
        toast,
        `Last Approved Billing`,
        error?.response?.data?.message ||
          `An approved draft couldn't be found!`,
        3000
      );
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return (
    <React.Fragment>
      <ErrorToast toastRef={toast} />
      {isFetching ? (
        <div className="text-center max-w-15rem">
          <ProgressSpinner className="h-2rem" />
        </div>
      ) : (
        <ConfirmationDialog
          tagKey="last-approved-draft-conf"
          Button={
            <WrapperButton type="button">
              <div className="text-18px font-bold cursor-pointer totalColor hover:underline">
                Use Last Approved Draft
              </div>
            </WrapperButton>
          }
          onConfirm={() => refetch()}
          message={
            'Are you sure you want to adjust the previous progress billing?'
          }
        />
      )}
    </React.Fragment>
  );
};

export default ProgressBillingLastApproved;
